
import { Component, Watch } from "vue-property-decorator";
import UpdateMixin from "@/mixins/Crud/UpdateMixin";
import CustomerForm from "@/views/BaseData/Customer/CustomerForm.vue";
import { BaseDataCustomer } from "@/config/Modules";
import { mixins } from "vue-class-component";
import { BaseDataCustomerStoreMixin } from "@/mixins/Stores/BaseData/CustomerStoreMixin";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import ImageViewAndUpload from "@/components/media/ImageViewAndUpload.vue";
import IMedia from "@/interfaces/IMedia";
import ICustomer from "@/interfaces/ICustomer";

@Component({
  components: { ImageViewAndUpload, CustomerForm },
  mixins: [UpdateMixin],
})
export default class CustomerUpdate extends mixins(BaseDataCustomerStoreMixin) {
  protected logo: IMedia | null = null;

  @Watch("getBaseDataCustomerItem", { immediate: true, deep: true })
  protected onCustomerItemChanged(customer: ICustomer | null): void {
    this.logo = customer?.logo ?? null;
  }

  protected init(): void {
    this.findOneBaseDataCustomerItemAction({
      id: this.$route.params.id,
      resource: this.resource,
    });
  }

  public get resource(): string {
    return BaseDataCustomer.resource;
  }

  public get descriptionField(): string {
    return BaseDataCustomer.description_field;
  }

  public updateItemAction(options: ICrudOptions): void {
    this.updateBaseDataCustomerItemAction(options);
  }

  protected get logoUploadUrl(): string {
    return [this.resource, this.$route.params.id, "upload"].join("/");
  }
}
